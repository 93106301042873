import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer-component.component.html',
  styles: [
    `
.footer {
  background-color: #f5f5f5;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.footer-custom {
  border: 2px solid #2E4C6D;
  border-style: solid none none none
}

i {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

i:hover {
  transform: scale(1.2);
}

    `
  ]
})
export class FooterComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
