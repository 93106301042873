import { RouterModule } from '@angular/router';
import { ContactGuard } from './pages/contacto/guards/contact.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './pages/home/home.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { SharedModule } from '../shared/shared.module';
import { SectionComponent } from './components/section/section.component';
import { EnConstruccionComponent } from './components/en-construccion/en-construccion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreciosComponentComponent } from './components/precios-component/precios-component.component';
import { AclaracionComponent } from './components/aclaracion/aclaracion.component';

@NgModule({
  declarations: [
    HomeComponent,
    ContactoComponent,
    SectionComponent,
    EnConstruccionComponent,
    PreciosComponentComponent,
    AclaracionComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HomeComponent,
    ContactoComponent
  ],
  providers: [ContactGuard]
})
export class PivotModule { }
