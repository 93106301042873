<div class="container animated fadeIn">
  <div class="pt-5">
        <div class="row my-md-2">
          <div class="col">
            <h1 class="lead">Llevamos tu negocio al siglo XXI,
              <strong>Desarrollamos soluciones técnologicas y asesoramos</strong> a todo tipo de empresas, trabajamos con las técnologias más optimas para tu negocio y presupuesto, siéntete libre de contactarnos por cualquier inquietud que tengas, estaremos gustosos de atenderte.</h1>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col">
                  <form novalidate class="form-horizontal mb-md-5 py-md-3" (ngSubmit)="onSave()" [formGroup]="contactForm">
                      <fieldset>
                          <legend class="text-center header text-custom-titles">Contáctanos</legend>
                          <div class="form-group">
                              <div class="col">
                                  <input formControlName="firstName" placeholder="Nombres" class="form-control"
                                  [class]="isValidField('firstName')">
                                  <div class="invalid-feedback">
                                    <span *ngIf="getControl.firstName.errors?.required">
                                      Por favor ingresar sus nombres.
                                    </span>
                                  </div>
                              </div>
                              <div class="col mt-2">
                                  <input formControlName="lastName" placeholder="Apellidos" class="form-control"
                                  [class]="isValidField('lastName')">
                                  <div class="invalid-feedback">
                                    <span *ngIf="getControl.lastName.errors?.required">
                                      Por favor ingresar sus apellidos.
                                    </span>
                                  </div>
                              </div>
                              <div class="col mt-2">
                                  <input formControlName="email" placeholder="Correo" class="form-control"
                                  [class]="isValidField('email')">
                                  <div class="invalid-feedback">
                                    <span *ngIf="getControl.email.errors?.required">
                                      Por favor ingresar su correo.
                                    </span>
                                    <span *ngIf="getControl.email.errors?.pattern">
                                      Por favor ingresar un correo válido.
                                    </span>
                                  </div>
                              </div>
                              <div class="col mt-2">
                                  <input formControlName="phone" placeholder="Teléfono" class="form-control"
                                  [class]="isValidField('phone')">
                                  <div class="invalid-feedback">
                                    <span *ngIf="getControl.phone.errors?.required">
                                      Por favor ingresar su teléfono o celular.
                                    </span>
                                  </div>
                              </div>
                              <div class="col mt-2">
                                  <textarea class="form-control" formControlName="message"
                                  placeholder=
                                  "Ingresa tu consulta aquí. Te responderemos lo más pronto posible." rows="7"
                                  [class]="isValidField('message')"></textarea>
                                  <div class="invalid-feedback">
                                    <span *ngIf="getControl.message.errors?.required">
                                      Por favor ingresar su consulta o mensaje.
                                    </span>
                                    <span *ngIf="getControl.message.errors?.minlength">
                                      Por favor ingresar un mínimo de 10 caracteres.
                                    </span>
                                  </div>
                              </div>
                              <div class="col text-center mt-2">
                                  <button type="submit" class="btn btn-custom-enviar btn-lg text-custom">Enviar</button>
                              </div>
                          </div>
                      </fieldset>
                  </form>
              </div>
      </div>
  </div>
</div>
