import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuComponent } from './menu/menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { SliderComponent } from '../pivot/components/slider/slider.component';
import { ContactGuard } from '../pivot/pages/contacto/guards/contact.guard';
import { FooterComponentComponent } from './footer-component/footer-component.component';


@NgModule({
  declarations: [
    MenuComponent,
    NotFoundComponent,
    SliderComponent,
    FooterComponentComponent
  ],
  exports: [
    MenuComponent,
    SliderComponent,
    FooterComponentComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SharedModule { }
