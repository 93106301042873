import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ContactoComponent } from "../contacto.component";
import Swal from 'sweetalert2';

@Injectable()
export class ContactGuard implements CanDeactivate<ContactoComponent> {
  canDeactivate(component: ContactoComponent): boolean | Observable<boolean> | Promise<boolean> {

    if (component.contactForm.dirty) {
      const name = component.contactForm.get('firstName')?.value || 'Estimado usuario';
      Swal.fire({
        title: `${name}, desea salir del formulario y perder los campos ingresados?`,
        showCancelButton: true,
        confirmButtonText: `Sí, salir`
      }).then((result:any) => {
        return result.isConfirmed ? true : false;
      });
    }
    return true;
  }
}
