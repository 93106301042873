import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable } from "rxjs";

export interface Contact {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  message: string
}

@Injectable()
export class DataService {

  contacts?: Observable<Contact>;
  private contactsCollection: AngularFirestoreCollection<Contact>;

  constructor(private readonly angularFireStore: AngularFirestore)
  {
    this.contactsCollection = angularFireStore.collection<Contact>('PivotContacts');
  }

  async onSave(contactForm:Contact): Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const fecha = new Date().toLocaleString();
        const id = this.angularFireStore.createId();
        const data = { id, ...contactForm, fecha };
        const result = this.contactsCollection.doc(id).set(data);

        resolve(result);

      } catch (error) {

        reject(error);

      }
    })
  }
}
