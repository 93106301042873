import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-precios-component',
  templateUrl: './precios-component.component.html',
  styles: [
 `
     a {
      text-decoration: none;
      color: black
     }
    .card {
      transition-property: All;
      transition-duration: 2s;
      transition-timing-function: ease-in-out;
      border-width: 4px;
    }

    .card-color {
      background-color: #2E4C6D
    }

    .card-body-otros-color {
      border: 1px dashed #DADDFC
    }

    .card-body-color {
      border: 1px solid #DD6E42
    }

    .card:hover {
      transform: scale(1.5);
      border-color: #DD6E42;
    }

    .custom-precios {
      background-color: #2E4C6D;
      border: 3px solid #DD6E42
    }

    .text-custom {
      color: #DADDFC;
    }

    .btn-custom-precios {
      transition-property: all;
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
      background-color: #DD6E42
    }

    .btn-custom-precios:hover {
      transform: scale(1.1);
    }

    .btn-outlet-custom {
      border: 2px dashed #DD6E42;
      color: #DD6E42
    }

    .text-custom-button {
      color: #DADDFC
    }
 `
  ]
})
export class PreciosComponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
