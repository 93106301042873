// import { ContactGuard } from './pivot/pages/contacto/guards/contact.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pivot/pages/home/home.component';
import { ContactoComponent } from './pivot/pages/contacto/contacto.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

const routes: Routes = [

  {
    path: 'portafolio',
    loadChildren: ()=> import('./pivot/portafolio/portafolio.module').then( modulo => modulo.PortafolioModule)
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'contacto',
    component: ContactoComponent
    //canDeactivate: [ContactGuard]
  },
  {
    path: 'notfound',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'notfound'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
