import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styles: [
    `
    .center_div
    {
    margin: 0 auto;
    width:100% /* value of your choice which suits your alignment */
    }

    .btn-custom-enviar {
      background-color: #DD6E42;
    }

    .text-custom {
      color: #DADDFC;
    }
    .header
    {
    font-size: 27px;
    padding: 10px;
    }
    .bigicon {
    font-size: 35px;
    color: #36A0FF;
    }

    button {
      transition-property: all;
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
    }

    button:hover {
      transform: scale(1.1);
    }

    `
  ],
  providers: [DataService]
})
export class ContactoComponent implements OnInit {

  contactForm!: FormGroup;
  private isEmail: RegExp = /\S+@\S+\.\S+/;

  constructor( private formBuilder: FormBuilder,
               private dataService: DataService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  async onSave() : Promise<void> {
    if (this.contactForm.valid)
    {
      try {
        const contactForm = this.contactForm.value;
      await this.dataService.onSave(contactForm);
      //Notifica que todo fue correcto
      Swal.fire(
        'Mensaje enviado!',
        'Te contactaremos lo más pronto posible',
        'success'
      )
      this.contactForm.reset();
      } catch (error) {
        alert(error);
      }
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se pudo enviar el mensaje!',
        footer: 'Lo más probable es que algún campo no fue ingresado correctamente'
      })
    }
  }

  isValidField(campo:string): string {
    const validatedField = this.contactForm.get(campo);

    return (!validatedField?.valid && validatedField?.touched)
    ? 'is-invalid' : validatedField?.touched ? 'is-valid' : '';
  }

  private initForm() : void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.isEmail)]],
      phone: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(10)]]
    })
  }

  get getControl(){
    return this.contactForm.controls;
  }
}
