<footer class="footer mt-auto py-2 footer-custom">
  <div class="container">
    <div class="row justify-content-center text-center">
      <div class="col-md-4">
          <b>Medios de contacto</b>
          <div class="h3">
              <a  href="https://www.facebook.com/PivotSoluciones"
                  target="_blank">
                <i class="fab fa-facebook-square mr-2"></i>
              </a>
              <a  href="https://api.whatsapp.com/send?phone=+593984334901&text=Quiero%20m%C3%A1s%20informaci%C3%B3n"
                  target="_blank">
                <i class="fab fa-whatsapp-square mr-2 text-success"></i>
              </a>
              <a  href="https://twitter.com/PivotSoluciones"
                  target="_blank">
                  <i class="fab fa-twitter-square mr-2 text-info"></i>
              </a>
              <a  href="mailto:pivot.soluciones@gmail.com"
                  target="_blank">
                  <i class="fas fa-envelope-square text-secondary"></i>
              </a>
        </div>
      </div>
      <div class="col-md-4">
          <b>Acerca de nosotros</b>
          <p>Empresa desarrolladora de soluciones Tecnológicas 100% Ecuatoriana</p>
      </div>
    </div>
    <div class="row text-center">
      <div class="col">
        <span class="text-muted"><b>Pivot Soluciones</b> - creado con <i class="fas fa-heart text-danger"></i> en Ecuador</span>
        <hr>
        <span class="text-muted">Powered by <b>Vercel</b></span>
      </div>
    </div>
  </div>
</footer>
