import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styles: [
    `
    .custom-jumbotron {
      background-color: #2E4C6D;
      border: 3px solid #DD6E42
    }

    .text-custom {
      color: #DADDFC;
    }

    .hr-custom {
      border-color: #DADDFC;
    }

    .btn-custom {
      background-color: #DD6E42;
      border: 3px solid #DADDFC;
      transition-property: all;
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;
    }

    .btn-custom:hover {
      transform: scale(1.1);
      color: #DD6E42;
      background-color: #2E4C6D;
      border: 3px solid #DADDFC;
    }
    /* Estilo para hacer linea como post de twitter */
    /* h2.display-4 {
        position: relative;
        font-size: 48px;
        cursor: pointer;
    }

    h2.display-4:before {
        content: "";
        position: absolute;
        width: 0%;
        left: 50%;
        height: 3px;
        background-color: #2085bc;
        bottom: 0;
        transition: all 500ms ease-in;
    }

    h2.display-4:hover:before {
      width: 100%;
      left: 0;
    } */
    /* Estilo para hacer linea como post de twitter */

    `
  ]
})
export class SectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
