<section class="container pt-4 mx-xs-0 animated fadeIn">

  <div class="jumbotron custom-jumbotron">
    <h1 class="text-custom-titles">Desarrollo de software y Facturación electrónica</h1>
    <hr class="my-4 hr-custom">
    <p class="lead text-custom">Pivot Soluciones nace con el objetivo de crear soluciones de software que estén a la vanguardia con el desarrollo tecnológico actual, dirigido al apoyo de las pequeñas y medianas empresas, por lo cual nos hemos comprometido en ofrecerles <strong>servicios y productos de calidad a un costo asequible</strong>, permitiendo así que todos sean participes de esta revolución tecnológica.</p>
    <a  class="btn btn-custom btn-lg text-custom"
        [routerLink]="['contacto']"
        role="button ">Más información</a>
  </div>

  <h2 class="display-4 text-center text-custom-titles"><span>Facturación Electrónica Ecuador</span></h2>
  <p class="lead text-center my-3">Olvídate de la facturación física y engorrosa de décadas pasadas, actualízate a la forma <em>moderna y más fácil de realizar los trámites de facturación que requieren tu negocio</em>, utiliza nuestro sistema sencillo para realizar tus facturas, sin pagos mensuales, un solo pago para siempre*.</p>

</section>
<!-- /Seccion 1 -->
