<nav class="navbar navbar-light bg-light navbar-expand-lg container">
    <a  routerLink=""
        class="navbar-brand text-primary">
      <img src="assets/images/LOGO_PIVOT.svg" width="100" height="50" alt="" loading="lazy">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto mr-4">
        <li class="nav-item">
          <a  [routerLink]="['']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="nav-link">Inicio</a>
        </li>
        <li class="nav-item">
          <a  routerLink="blog"
              routerLinkActive="active"
              class="nav-link">Blog</a>
        </li>
        <li class="nav-item">
            <a  routerLink="portafolio"
                routerLinkActive="active"
                class="nav-link">Portafolio</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Servicios
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a  [routerLink]="['portafolio/pivot-pdv']"
                routerLinkActive="active"
                class="dropdown-item">
                Facturación electrónica
              </a>
            <a  [routerLink]="['portafolio/desarrollo-web']"
                routerLinkActive="active"
                class="dropdown-item">
                Desarrollo web
              </a>
            <a  [routerLink]="['portafolio/desarrollo-desktop']"
                routerLinkActive="active"
                class="dropdown-item">
                Soluciones a la medida
              </a>
          </div>
        </li>
        <li class="nav-item">
          <a  routerLink="contacto"
              routerLinkActive="active"
              class="nav-link">Contacto</a>
        </li>
      </ul>
      <span class="navbar-text text-custom">
        Móvil: <b>+593 984 334 901</b>
      </span>
    </div>
</nav>
