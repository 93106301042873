import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styles: [
    `
    .fondo404Page {
      background-color: #88BA2A;
      height: 100%;
      min-height: 95vh;
    }
    `
  ]
})
export class NotFoundComponent {

  constructor() { }

}
