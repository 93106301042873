<section class="container pt-4 animated fadeIn">
  <div class="pricing-header px-3 pt-md-3 py-4 mx-auto text-center custom-precios">
    <h2 class="display-4 text-custom-titles">Precios</h2>
    <p class="lead text-custom">Por lanzamiento de nuestro sistema el precio se puede llegar a acordar a conveniencia del cliente dependiendo del tipo de negocio en el que se desee utilizar.</p>
  </div>
  <!-- Tarjetas -->
  <div class="container my-4">
    <div class="card-deck mb-3 text-center">
      <!-- Card otros sistemas -->
      <div class="card mb-4 shadow-sm" data-aos="zoom-in-right">
        <div class="card-header card-color">
          <h4 class="my-0 font-weight-normal text-custom-titles">Otros sistemas</h4>
        </div>
        <div class="card-body card-body-otros-color">
          <h1 class="card-title pricing-card-title">$25 <small class="text-muted">/ mes</small></h1>
          <ul class="list-unstyled mt-3 mb-4">
            <li>Pagos mensuales por siempre.</li>
            <li>Tu información no la tienes tú.</li>
            <li>Facturas limitadas según el plan que pagues.</li>
            <li>Los precios pueden cambiar en el futuro.</li>
          </ul>
          <button type="button" class="btn btn-lg btn-block btn-outlet-custom text-buttom-custom" disabled>:(</button>
        </div>
      </div>
      <!-- /Card otros sistemas -->

      <!-- Card pivot pdv -->
      <div class="card mb-4 shadow-sm" data-aos="zoom-in">
          <a routerLink="contacto">
          <div class="card-header card-color">
            <h4 class="my-0 font-weight-normal text-custom-titles">Pivot PDV</h4>
          </div>
          <div class="card-body card-body-color">
            <h1 class="card-title pricing-card-title">$250 <small class="text-muted">/ pago único</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Facturas ilimitadas.</li>
              <li>Registro de productos.</li>
              <li>Registro de clientes.</li>
              <li>Registro de compras.</li>
              <li>Control de stock.</li>
              <li>Cuentas por cobrar.</li>
            </ul>
            <button type="button"
            class="btn btn-lg btn-block btn-custom-precios text-custom-button"
            routerLink="contacto">
            <b>Contáctanos</b>
          </button>
        </div>
      </a>
      </div>
      <!-- /Card pivot pdv -->

      <!-- Card pivot pdv + código -->
      <div class="card mb-4 shadow-sm" data-aos="zoom-in-left">
          <a routerLink="contacto">
          <div class="card-header card-color">
            <h4 class="my-0 font-weight-normal text-custom-titles">Pivot PDV + Código fuente</h4>
          </div>
          <div class="card-body card-body-color">
            <h1 class="card-title pricing-card-title">$850 <small class="text-muted">/ pago único</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Pivot PDV.</li>
              <li>Eres el dueño total del sistema.</li>
              <li>Podras modificar y hacer con el lo que desees.</li>
              <li>Podrás encargarnos modificaciones personalizadas (costo extra a acordar).</li>
            </ul>
            <button type="button"
            class="btn btn-lg btn-block btn-custom-precios text-custom-button"
            routerLink="contacto">
            <b>Contáctanos</b>
          </button>
        </div>
      </a>
      </div>
      <!-- /Card pivot pdv + código -->

    </div>
  </div>
  <!-- /Tarjetas -->
</section>
